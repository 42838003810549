
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import BIBOHistory from "../components/bibo/BIBOHistory.vue";

// import BalanceDetails from "@/components/cards/BalanceDetails.vue";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import ECShopAdvisory from "@/views/ECShopAdvisory.vue";


export default defineComponent({
  name: "bibo",
  components: {
    BIBOHistory,
    // BalanceDetails,
    // CompanyPolicy,
    // ECShopAdvisory
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("biboTab") || "0");
      setCurrentPageTitle("BIBO Compensation");

    });
    
  }
});
