
import { defineComponent } from "vue";
import {loadBIBO_summary}  from "@/api/bibo";

export default defineComponent({
  name: "bibohistory", 
  methods: {
    async getThisMonthAndYear(){
      var d = new Date();
      var m = d.getMonth();
      var y = d.getFullYear();
      this.month = m + 1;
      this.year = y;
    },  
    async loadBIBOSummary(){
      this.loading = true;
      this.totalcom = parseFloat("0.00");
      var params = {
        "month" : this.month,
        "year" : this.year
      }
      const response = await loadBIBO_summary(params); 
      this.list = response.data.pageList;
      this.list2 = response.data.pageList2;

      response.data.pageList2.forEach(element => {
        this.totalcom = this.totalcom + parseFloat(element.total);
      });
      this.loading = false;
    },
  },
  mounted(){
      this.getThisMonthAndYear();
      this.loadBIBOSummary();
  },
  data(){
      return {
        month:0,
        year: 0,
        list : [],
        list2 : [],
        totalcom : 0.00,
        loading: true,
      }
  },
  components: {
  },
  props: {
    widgetClasses: String
  }
});

